import { queryKey } from '@segunosoftware/equinox';
import { useMutation, useQuery } from '@tanstack/react-query';
import { MAIL_CHIMP_CONNECTED, MAIL_CHIMP_GET_LIST, MAIL_CHIMP_GET_LISTS, MAIL_CHIMP_GET_MERGE_FIELDS } from './query-keys';
import type { Get, Post } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export const useMailChimpConnect = () => {
	const { post } = useAuthenticatedFetch() as Post<any, any>;
	const {
		mutate: onGetRedirectUrl,
		data,
		isPending: redirectUrlLoading
	}: any = useMutation({
		mutationFn: (_: any) => post('/oauth/mailchimp/connect', _)
	});
	const redirectUrl = data?.redirectUrl;
	return { onGetRedirectUrl, redirectUrl, redirectUrlLoading };
};

export function useMailChimpConnected({ shop, code }: { shop: string; code: string }) {
	const { get } = useAuthenticatedFetch() as Get<any>;
	const { isFetching: isConnectedLoading, error } = useQuery({
		queryKey: queryKey(MAIL_CHIMP_CONNECTED),
		queryFn: () => get(`/oauth/mailchimp/connected?shop=${shop}&code=${code}`),
		enabled: !!shop && !!code
	});
	return { isConnectedLoading, error };
}

export function useMailChimpDisconnect() {
	const { post } = useAuthenticatedFetch() as Post<any, void>;
	const { mutate: onDisconnect, isPending: isDisconnectLoading } = useMutation({
		mutationFn: (payload: any) => post('/oauth/mailchimp/disconnect', payload)
	});
	return { onDisconnect, isDisconnectLoading };
}

export function useMailChimpGetLists() {
	const { get } = useAuthenticatedFetch() as Get<any>;
	const {
		data: lists,
		isFetching: loading,
		refetch: onLoadLists
	} = useQuery({
		queryKey: queryKey(MAIL_CHIMP_GET_LISTS),
		queryFn: () => get(`/mailchimp/lists`)
	});
	return { lists, loading, onLoadLists };
}

export function useMailChimpGetList(id: string) {
	const { get } = useAuthenticatedFetch() as Get<any>;
	const { data: mailChimpList, isFetching: isMailChimpListLoading } = useQuery({
		queryKey: queryKey(MAIL_CHIMP_GET_LIST),
		queryFn: () => get(`/mailchimp/lists/${id}`),
		enabled: !!id
	});
	return { mailChimpList, isMailChimpListLoading };
}

export function useMailChimpGetMergeFields() {
	const { get } = useAuthenticatedFetch() as Get<any>;
	const {
		data: mergeFields,
		isFetching: isMergeFieldsLoading,
		refetch: onLoadMergeFields
	} = useQuery({
		queryKey: queryKey(MAIL_CHIMP_GET_MERGE_FIELDS),
		queryFn: () => get('/mailchimp/merge-fields')
	});
	return { mergeFields, isMergeFieldsLoading, onLoadMergeFields };
}
