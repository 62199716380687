import { useAppBridgeRouter } from '@segunosoftware/equinox';
import { NavigationMenu, Provider } from '@shopify/app-bridge-react';
import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { useMemo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import CustomLinkComponent from './CustomLinkComponent';
import OAuth from './components/oauth/OAuth';
import AppEmbedContainer from './containers/AppEmbedContainer';
import ProtectedRoute from './containers/ProtectedRoute';
import SentryWrapperContainer from './containers/SentryWrapperContainer';
import MailChimpConnectContainer from './containers/oauth/MailChimpConnectContainer';
import { useHostParam } from './hooks/useHostParam';

const SHOPIFY_APP_KEY = import.meta.env.VITE_SHOPIFY_KEY;

const NAVIGATION_LINKS = [
	{ label: 'Dashboard', destination: '/dashboard' },
	{ label: 'Settings', destination: '/settings' }
];

export default function App() {
	const location = useLocation();
	const host = useHostParam() ?? '';
	const router = useAppBridgeRouter();
	const isOAuthPath = location.pathname.startsWith('/oauth/shopify');
	const config = useMemo(
		() => ({
			apiKey: SHOPIFY_APP_KEY,
			host,
			forceRedirect: !isOAuthPath
		}),
		[host, isOAuthPath]
	);
	return (
		<AppProvider key={isOAuthPath.toString()} i18n={translations} linkComponent={CustomLinkComponent}>
			<Provider key={isOAuthPath.toString()} config={config} router={router}>
				<NavigationMenu navigationLinks={NAVIGATION_LINKS} />
				<SentryWrapperContainer>
					<Routes>
						<Route path="/oauth/mailchimp/connect" element={<ProtectedRoute element={<MailChimpConnectContainer />} />} />
						<Route path="/oauth/*" element={<OAuth />} />
						<Route path="/*" element={<ProtectedRoute element={<AppEmbedContainer />} />} />
					</Routes>
				</SentryWrapperContainer>
			</Provider>
		</AppProvider>
	);
}
