import { Stack, TextStyle } from '@segunosoftware/equinox';
import { Button, Checkbox, FormLayout, Modal, Spinner } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { useNavigation } from '../../hooks/useNavigation';

export default function MailChimpConnectionWrapper({ ...rest }) {
	const { onNavigateConnectMailchimp } = useNavigation();

	return <MailChimpConnection {...rest} onConnect={onNavigateConnectMailchimp} />;
}

class MailChimpConnection extends PureComponent {
	static propTypes = {
		account: PropTypes.shape({
			syncStatus: PropTypes.shape({
				initialSyncStarted: PropTypes.bool.isRequired,
				initialSyncComplete: PropTypes.bool.isRequired
			}).isRequired,
			mailChimpListId: PropTypes.string
		}).isRequired,
		onConnect: PropTypes.func.isRequired,
		onDisconnect: PropTypes.func.isRequired,
		onLoadMailChimpList: PropTypes.func.isRequired,
		getMailChimpList: PropTypes.func.isRequired,
		isMailChimpListLoading: PropTypes.bool,
		isDisconnectLoading: PropTypes.bool
	};

	static defaultProps = {
		isDisconnectLoading: false
	};

	state = {
		connectConfirmOpen: false,
		confirmedBackup: false,
		disconnectConfirmOpen: false,
		confirmDisconnect: false,
		listAttemptedToLoad: false
	};

	componentDidMount() {
		this.attemptToLoadList();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.disconnectConfirmOpen && !this.isConnected()) {
			this.onCloseDisconnectConfirm();
		}
		this.attemptToLoadList();
	}

	isConnected() {
		return !!this.props.account.mailChimpListId;
	}

	attemptToLoadList() {
		const {
			account: { mailChimpListId },
			getMailChimpList,
			isMailChimpListLoading,
			onLoadMailChimpList
		} = this.props;
		const { listAttemptedToLoad } = this.state;
		if (!mailChimpListId || listAttemptedToLoad || getMailChimpList(mailChimpListId) || isMailChimpListLoading(mailChimpListId)) {
			return;
		}
		this.setState({
			listAttemptedToLoad: true
		});
		onLoadMailChimpList(mailChimpListId);
	}

	onOpenDisconnectConfirm = () => {
		this.setState({
			disconnectConfirmOpen: true
		});
	};

	onCloseDisconnectConfirm = () => {
		this.setState({
			disconnectConfirmOpen: false
		});
	};

	isSyncing() {
		const {
			account: {
				syncStatus: { initialSyncStarted, initialSyncComplete }
			}
		} = this.props;
		return initialSyncStarted && !initialSyncComplete;
	}

	getSubtext() {
		const {
			getMailChimpList,
			account: { mailChimpListId }
		} = this.props;
		const isConnected = this.isConnected();
		if (!isConnected) {
			return 'No account connected';
		}
		const mailchimpList = getMailChimpList(mailChimpListId);
		if (mailchimpList) {
			return `Connected to "${mailchimpList.name}" audience`;
		}
		return 'Loading...';
	}

	render() {
		const { onConnect, onDisconnect, isDisconnectLoading } = this.props;
		const { disconnectConfirmOpen, confirmDisconnect } = this.state;
		const isConnected = this.isConnected();
		return (
			<>
				<Stack alignment="center" wrap={false}>
					{this.isSyncing() ? <Spinner /> : <img src="/images/mailchimp-logo.png" alt="Mailchimp" width="40" height="40" />}
					<Stack.Item fill>
						<Stack spacing="extraTight" vertical>
							<div>Mailchimp</div>
							<TextStyle variation="subdued">{this.getSubtext()}</TextStyle>
						</Stack>
					</Stack.Item>
					<Button onClick={isConnected ? this.onOpenDisconnectConfirm : onConnect} variant={!isConnected ? 'primary' : undefined}>
						{isConnected ? 'Disconnect' : 'Connect'}
					</Button>
				</Stack>
				<Modal
					title="Disconnect Mailchimp"
					primaryAction={{
						content: 'Disconnect',
						onAction: onDisconnect,
						disabled: !confirmDisconnect,
						destructive: true,
						loading: isDisconnectLoading
					}}
					secondaryActions={[{ content: 'Cancel', onAction: this.onCloseDisconnectConfirm }]}
					onClose={this.onCloseDisconnectConfirm}
					open={disconnectConfirmOpen}>
					<Modal.Section>
						<FormLayout>
							<div>Are you sure you want to disconnect your Mailchimp account?</div>
							<Checkbox
								label="I understand this will stop syncing my data and delete the connected site in Mailchimp"
								checked={confirmDisconnect}
								onChange={confirmed => this.setState({ confirmDisconnect: confirmed })}
							/>
						</FormLayout>
					</Modal.Section>
				</Modal>
			</>
		);
	}
}
