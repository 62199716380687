import MailChimpConnect from '../../components/oauth/MailChimpConnect';
import { useMailChimpConnect } from '../../hooks/useMailChimp';

export default function MailChimpConnectContainer(props) {
	const { onGetRedirectUrl, redirectUrl, redirectUrlLoading } = useMailChimpConnect();

	return (
		<MailChimpConnect onGetRedirectUrl={onGetRedirectUrl} redirectUrlLoading={redirectUrlLoading} redirectUrl={redirectUrl} {...props} />
	);
}
