export const USER = 'USER';
export const LOAD_SYNC_STATUS = 'LOAD_SYNC_STATUS';
export const MAIL_CHIMP_CONNECTED = 'MAIL_CHIMP_CONNECTED';
export const MAIL_CHIMP_GET_LISTS = 'MAIL_CHIMP_GET_LISTS';
export const MAIL_CHIMP_GET_MERGE_FIELDS = 'MAIL_CHIMP_GET_MERGE_FIELDS';
export const MAIL_CHIMP_GET_LIST = 'MAIL_CHIMP_GET_LIST';
export const SHOPIFY_INSTALL = 'SHOPIFY_INSTALL';
export const SHOPIFY_CALLBACK = 'SHOPIFY_CALLBACK';
export const SHOPIFY_BILLING_INSTALL = 'SHOPIFY_BILLING_INSTALL';
export const SHOPIFY_BILLING_CALLBACK = 'SHOPIFY_BILLING_CALLBACK';
