import { LoadingScreen } from '@segunosoftware/equinox';
import { Navigate, useLocation } from 'react-router-dom';
import { useAccountFetch } from '../hooks/useAccount';

function renderElement(account, location, element) {
	const isSuspendedPath = location.pathname.indexOf('/suspended') === 0;
	const isFrozenPath = location.pathname.indexOf('/frozen') === 0;
	if (account.suspended && !isSuspendedPath) {
		return <Navigate to="/suspended" />;
	} else if (account.billingStatus === 'frozen' && !isFrozenPath) {
		return <Navigate to="/frozen" />;
	}
	if ((!account.suspended && isSuspendedPath) || (account.billingStatus !== 'frozen' && isFrozenPath)) {
		return <Navigate to="/" />;
	}
	return element;
}

export default function ProtectedRoute({ element, ...rest }) {
	const location = useLocation();
	const { account } = useAccountFetch(true);
	return account ? renderElement(account, location, element) : <LoadingScreen />;
}
