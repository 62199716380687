import { useState } from 'react';
import MailChimpConnection from '../../components/dashboard/MailChimpConnection';
import { useAccount } from '../../hooks/useAccount';
import { useMailChimpDisconnect, useMailChimpGetList } from '../../hooks/useMailChimp';

export default function MailChimpConnectionContainer(props) {
	const account = useAccount();
	const [queryId, setQueryId] = useState(null);
	const { onDisconnect, isDisconnectLoading } = useMailChimpDisconnect();
	const { mailChimpList, isMailChimpListLoading } = useMailChimpGetList(queryId);

	return (
		<MailChimpConnection
			account={account}
			isMailChimpListLoading={id => (id === queryId ? isMailChimpListLoading : false)}
			getMailChimpList={id => (id === queryId ? mailChimpList : null)}
			onLoadMailChimpList={id => setQueryId(id)}
			onDisconnect={onDisconnect}
			isDisconnectLoading={isDisconnectLoading}
			{...props}
		/>
	);
}
